


.dashboard-pos{
    /* display: grid; */
    /* margin-left: 7.5%;
    margin-right: 7.5%; */
    /* margin-left: 1rem;
    margin-right: 1rem; */
    /* background-color: #EFF2F5; */

    /* display: flex;
    flex-direction: row;
    gap: 1rem; */
    display: grid;
    /* grid-template-columns: 2fr 1fr; */
    grid-template-columns: 65% 35%;
}


.pos-products-container{   
    background-color: #EFF2F5;
    display: flex;
    flex-direction: column;
}

.pos-products-container .MuiDivider-root{   
    border-color: unset;
}

.pos-search-bar{
    /* margin: 2rem */
    margin: 2rem 2rem 0.5rem;
}

.pos-products-container .custom-input .MuiAutocomplete-endAdornment{
    margin-top: 0;
    /* border: 1px red solid; */
}


.pos-products{
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    background-color: #EFF2F5;
    /* height: 90vh; */
    /* height: fit-content; */
    /* height: 100%; */
    padding: 2rem;
    justify-content: center;
    /* border: 1px solid red; */
  }


.pos-products .pos-card{
    background-color: transparent;
    border: 1px solid #009688;
    width: 32%;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1rem;
}

.empty-pos-card {
    background-color: transparent;
    /* border: 1px solid #009688; */
    border: 1px solid #cfd8dc;
    width: 32%;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    padding: 1rem;
  }


.pos-products .pos-card:hover{
    cursor: pointer;
    /* border: 1px solid #e91e63 */
    border: 1px solid #ee8c21

}

.pos-products .pos-card .pos-card-content{
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.pos-card-content .pos-product-title{
    font-size:1.25rem;
    font-weight: 200;
    /* border: 1px solid red; */
    /* white-space: nowrap;
    /* text-overflow: ellipsis; */ 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%; /* Explicitly set the width */
    text-align: center; /* Center content horizontally */


    text-transform: uppercase;
    /* color: #95aac9; */
    /* font-size:.65rem; */
    font-size:1rem;
    /* font-weight: 300; */
}

.pos-card-content .pos-product-price{
    text-transform: uppercase;
    /* color: #95aac9; */
    font-size:.65rem;
    /* font-size:0.9rem; */
    font-weight: 500;
}

.pos-products-container .custom-mui-pagination{
    background-color: transparent;
}


.pos-products-container .ant-card{
    background: transparent;
    /* border: 1px solid rgba(0, 0, 0, 0.23); */
    border: 1px solid rgba(0, 0, 0, 0.20);

    margin-top: 1.5rem;
    font: 0.9rem
}

.pos-products-container .ant-card .ant-card-head{
    /* background: transparent; */
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.23); */
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    font-size: unset;
    font-weight: unset;
}

.pos-products-container .ant-card-head-title, .pos-products-container .ant-card-extra{
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* padding: 1rem; */
}

.pos-products-container .ant-card-head-title{
    color: rgba(0, 0, 0, 0.50);
    /* padding: 1rem; */
}

.pos-products-container .ant-card .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border:1px solid rgba(0, 0, 0, 0.15);

}

/* .pos-products-container .ant-card .css-1mhojvz-MuiInputBase-root-MuiOutlinedInput-root{ */

.pos-products-container .ant-card .MuiAutocomplete-inputRoot{
    font-size: 0.9rem;
}


.cart{
    /* width: 40%; */
    /* display: flex;
    flex-direction: column; */
    padding-left: 1.5rem;
    padding-right: 2rem;
}

.cart-table .styled-table {
    /* border: 1px solid red; */
    background-color: transparent; 
    /*border-collapse: separate;
    border-spacing: 5px;  */
}

.cart-table .styled-table th,
.cart-table .styled-table td {
    /* border: 1px solid red; */
    /*border-collapse: separate;
    border-spacing: 5px;  */
    padding-left: 0;
}

.cart-table .styled-table tbody tr:hover {
    /* background-color: #f4eee3; */
    background-color: transparent;

  }

.cart-table .styled-table td button {
    /* border: 1px solid red; */
    /*border-collapse: separate;
    border-spacing: 5px;  */
    /* padding-left: 0; */
    /* border: 1px solid red; */
    padding: 0;
    /* font-size: 0.25rem; */
}




.cart-table .styled-table th:first-child,
.cart-table .styled-table td:first-child{
    width: 60%;
    /* text-align: right; */
    /* padding-right: 1rem; */
    /* border: 1px red solid */
  }
.cart-table .styled-table th:nth-child(2),
.cart-table .styled-table td:nth-child(2){
    width: 20%;
    text-align:center;
    /* padding-right: 1rem; */
    /* border: 1px red solid; */
    /* padding-left: 1.5rem; */
    /* padding-right: 0.5rem; */

  }


.cart-table .styled-table td:nth-child(2) button svg {
    /* border: 1px solid red; */
    /* padding: 0; */

    border-radius: 50%;
    border: 1px solid grey;
    font-size: 1.15rem;
    font-weight: 100;
    /* display: none; */
}

/* .cart-table .styled-table td:hover { 
    background-color: #009688;
} */

/* .cart-table .styled-table td:nth-child(2):hover button svg {
    display: block;
} */

.cart-table .styled-table tbody tr:hover td:nth-child(2) button svg {
    /* background-color: #f4eee3; */
    /* background-color: transparent; */
    display: block;
  }

.cart-table .styled-table td:nth-child(2) button svg:hover {
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid #ee8c21;
    display: block;
}

/* .cart-table .styled-table td:nth-child(2) .pos-icon-reduce { */

.pos-icon {
    display: none;
}

.pos-icon-reduce {
    margin-right: 2px;
    /* display: none; */
}

/* .cart-table .styled-table td:nth-child(2) .pos-icon-add { */
.pos-icon-add {
    margin-left: 2px;
    /* display: none; */
}


.cart-table .styled-table th:nth-child(3),
.cart-table .styled-table td:nth-child(3){
    width: 15%;
    text-align: right;
    padding-right: 1rem;
    /* border: 1px red solid */
  }


.cart-table .styled-table th:last-child,
.cart-table .styled-table td:last-child{
    width: 5%;
    /* text-align: right; */
    /* padding-right: 1rem; */
  }


/* .cart-table .styled-table td,
.cart-table .styled-table th {
  background-color: #f9fbfd;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
} */

.cart-total{
    width: 100%;
    display: flex;
}

.cart-total-text{
    font-size: 2.5rem;
    font-weight: 200;
    margin-left: auto;
}

.cart-payment-details{
    margin-top: 1rem;
    /* width: 100%; */
    /* display: flex; */
}

.cart-payment-details .cart-payment-form .zero-margin-top{
/* margin-top: -16px; */
margin-top: 0;
border: 1px solid red;

}

.cart-payment-details .cart-payment-form .custom-input .MuiAutocomplete-endAdornment{
/* margin-top: -16px; */
margin-top: 0;
}

.pos-button{
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: #166281;
    border: 1px solid #166281;
    width: 100%;
    height: 3rem
}

.pos-button:hover{
    /* background-color: #ee8c21; */
    /* background-color: transparent; */
    /* background-color: #166281; */
    /* border: 2px solid #ee8c21 */



}