/* .pos-products-container form .custom-input .MuiAutocomplete-endAdornment{
    margin-top: 0;
    border: 1px red solid;
} */

.product-details-container .styled-table {
    background-color: transparent;
    border-collapse: separate;
    border-spacing: 5px;
    /* td,th{
      background-color: #f9fbfd;
      padding-top: 0.65rem;
      padding-bottom: 0.65rem;
    } */
  }
  
  
  .product-details-container .styled-table td,
  .product-details-container .styled-table th {
    background-color: #f9fbfd;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
    /* padding-left: 0.85rem;
    padding-right: 0.85rem; */
    /* padding: 1rem; */
    /* white-space: nowrap; */
    /* overflow: auto; */
  }

  .product-details-container .styled-table td:first-child,
  .product-details-container .styled-table th:first-child {
    width: 20%;
    padding-left: 1rem;
  }
  
  /* .product-details-container .styled-table td:first-child,
  .product-details-container .styled-table th:first-child, */
  .product-details-container .styled-table td:nth-child(2),
  .product-details-container .styled-table th:nth-child(2) 
  {
    width: 45%;
    padding-left: 1rem;
  }
  
  .product-details-container .styled-table td:nth-child(3),
  .product-details-container .styled-table th:nth-child(3),
  .product-details-container .styled-table td:nth-child(4),
  .product-details-container .styled-table th:nth-child(4)
  /* .product-details-container .styled-table td:nth-child(5),
  .product-details-container .styled-table th:nth-child(5)  */
  {
    width: 10%;
    text-align: right;
    padding-right: 1rem;
  }

  .product-details-container .styled-table td:nth-child(5),
  .product-details-container .styled-table th:nth-child(5) {
    width: 12.5%;
    text-align: right;
    padding-right: 1rem;
  }
  
  .product-details-container .styled-table td:last-child button {
    /* background-color: #f9fbfd; */
    padding: 0;
    margin: 0;
  }